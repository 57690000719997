var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  })},[(!!_vm.token)?_c('div',{style:({
      width: '400px',
      maxWidth: '90vw'
    })},[_c('h3',{staticClass:"mb-5"},[_vm._v("Reset password")]),(!_vm.sended)?[_c('b',[_vm._v("Password")]),_c('v-text-field',{staticClass:"mt-1",attrs:{"solo":"","label":"Password","type":_vm.passwordVisible ? 'text' : 'password',"clearable":"","append-icon":_vm.passwordVisible ? 'mdi-eye-off' : 'mdi-eye',"loading":_vm.loading},on:{"click:append":() => _vm.passwordVisible = !_vm.passwordVisible},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b',[_vm._v("Conferma password")]),_c('v-text-field',{staticClass:"mt-1",attrs:{"solo":"","label":"Conferma password","type":_vm.passwordVisible ? 'text' : 'password',"clearable":"","rules":[
          (value) => value == _vm.password ? true : 'Password diverse'
        ],"append-icon":_vm.passwordVisible ? 'mdi-eye-off' : 'mdi-eye',"loading":_vm.loading},on:{"click:append":() => _vm.passwordVisible = !_vm.passwordVisible},model:{value:(_vm.passwordConfirm),callback:function ($$v) {_vm.passwordConfirm=$$v},expression:"passwordConfirm"}})]:_c('div',{staticClass:"mt-6 mb-6 text-center"},[_vm._v(" Il reset della password è avvenuto correttamente. ")]),(!_vm.sended)?_c('v-btn',{attrs:{"color":"primary","block":"","loading":_vm.loading,"disabled":!_vm.valid},on:{"click":_vm.handleResetClick}},[_vm._v("Resetta")]):_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":_vm.handleBackToLoginClick}},[_vm._v("Torna al login")])],2):_c('div',{style:({
      width: '400px',
      maxWidth: '90vw'
    })},[_c('div',{staticClass:"mb-6 text-center"},[_vm._v(" Il reset della password è possibile solo tramite il link inviato per messaggio. ")]),_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":_vm.handleBackToLoginClick}},[_vm._v("Torna al login")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }